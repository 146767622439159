import { BASE_API_URL } from "config/config";
import httpExpection from "utils/httpException";

export const getAllEvents = async () => {
  try {
    const response = await fetch(`${BASE_API_URL}events`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const getActiveEvent = async () => {
  try {
    const response = await fetch(`${BASE_API_URL}events/active`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const setActiveEvent = async (eventId) => {
  try {
    const response = await fetch(`${BASE_API_URL}events/active`, {
      method: "POST",
      body: JSON.stringify({
        eventId,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const createAustriacoEvent = async (name, companyId, invoiceDesc, invoiceService) => {
  try {
    const response = await fetch(`${BASE_API_URL}events`, {
      method: "POST",
      body: JSON.stringify({
        name,
        companyId: Number(companyId),
        invoiceDescription: invoiceDesc,
        invoiceIsService: invoiceService === 'true'
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const deleteEvent = async (id) => {
  try {
    const response = await fetch(`${BASE_API_URL}events/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const editEvent = async (id, json) => {
  try {
    const response = await fetch(`${BASE_API_URL}events/${id}`, {
      method: "PATCH",
      body: json,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};
