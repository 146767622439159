/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts

// import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

import Icon from "@mui/material/Icon";
import Payment from "layouts/payment";
import { Navigate } from "react-router-dom";
import Events from "layouts/events";
import Collaborators from "layouts/collaborators";
import Recharges from "layouts/recharges";
import Markets from "layouts/markets";
import Products from "layouts/products";
import Purchases from "layouts/purchases";
import Invoices from "layouts/invoices";
import Guest from "layouts/guest";

const validateLoggedIn = () => {
  return window.localStorage.getItem("session") != null;
};

const routes = [
  {
    type: "collapse",
    name: "Eventos",
    key: "events",
    icon: <Icon fontSize="small">festival</Icon>,
    route: "/events",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Events />,
  },
  {
    type: "collapse",
    name: "Tiendas",
    key: "markets",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/markets",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Markets />,
  },
  {
    type: "collapse",
    name: "Productos",
    key: "products",
    icon: <Icon fontSize="small">shopping_bag</Icon>,
    route: "/products",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Products />,
  },
  {
    type: "collapse",
    name: "Colaboradores",
    key: "collaborators",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/collaborators",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Collaborators />,
  },
  {
    type: "collapse",
    name: "Recargas",
    key: "recharges",
    icon: <Icon fontSize="small">fact_check</Icon>,
    route: "/recharges",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Recharges />,
  },
  {
    type: "collapse",
    name: "Facturas",
    key: "invoices",
    icon: <Icon fontSize="small">fact_check</Icon>,
    route: "/invoices",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Invoices />,
  },
  {
    type: "collapse",
    name: "Compra de productos",
    key: "purchases",
    icon: <Icon fontSize="small">fact_check</Icon>,
    route: "/purchases",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Purchases />,
  },
  // {
  //   type: "collapse",
  //   name: "Recarga Manual",
  //   key: "payment",
  //   icon: <Icon fontSize="small">payment</Icon>,
  //   route: "/payment",
  //   component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Payment />,
  // },
  {
    type: "collapse",
    name: "QR Invitado",
    key: "guest",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/guest",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Guest />,
  },
  /*{
    type: "collapse",
    name: "Compras",
    key: "purchases",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/purchases",
    component: !validateLoggedIn() ? <Navigate to="/authentication/sign-in" /> : <Payment />,
  },*/
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  /* {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  }, */
];

export default routes;
