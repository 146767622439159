// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/austriaco-bg.jpg";
import { useState } from "react";
import { login } from "services/auth-service";
import { useMaterialUIController, setUser } from "context";
import { useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();

  const [message, setMessage] = useState("");

  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error al realizar la recarga"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const loginEvent = async () => {
    if (email !== "" && password !== "") {
      try {
        const response = await login(email, password);
        setUser(dispatch, response.data.user);
        window.localStorage.setItem("session", response.token);
        window.location.href = "/events";
      } catch (e) {
        if (e.message != null) {
          setMessage(e.message);
        } else {
          setMessage("Error en el servidor");
        }
        openErrorSB();
      }
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <h3 style={{ fontSize: 15 }}>Asociación de Amigos Guatemaltecos y Austriacos / El Tortugario</h3>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Correo electrónico"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="primary" onClick={() => loginEvent()} fullWidth>
                ingresar como colaborador
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {renderErrorSB}
    </BasicLayout>
  );
}

export default Basic;
