// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Modal from "@mui/material/Modal";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import {
  getAllEvents,
  getActiveEvent,
  createAustriacoEvent,
  deleteEvent,
  editEvent,
  setActiveEvent,
} from "services/event-service";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, FormControl, Tooltip, Typography } from "@mui/material";
import { CheckCircle, Public, PublicOff } from "@mui/icons-material";

function Events() {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const [message, setMessage] = useState("");
  const [companyId, setCompanyId] = useState('');

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openFinishModal, setOpenFinishModal] = useState(false);
  const handleOpenFinishModal = () => setOpenFinishModal(true);
  const handleCloseFinishModal = () => setOpenFinishModal(false);

  const [openCreateNew, setOpenCreateNew] = useState(false);
  const handleOpenCreateNew = () => setOpenCreateNew(true);
  const handleCloseCreateNew = () => setOpenCreateNew(false);

  const [name, setName] = useState("");
  const [invoiceDesc, setInvoiceDesc] = useState("");
  const [InvoiceService, setInvoiceService] = useState(true);

  const columns = [
    { Header: "Nombre", accessor: "name", width: "45%", align: "left" },
    { Header: "Creador", accessor: "creator", align: "left" },
    { Header: "Factura Descripcion", accessor: "invoiceDesc", align: "left" },
    { Header: "Factura Bien/Servicio", accessor: "invoiceService", align: "left" },
    { Header: "Activo", accessor: "active", align: "left" },
    { Header: "Opciones", accessor: "options", align: "left" },
    { Header: "Finalizar Evento", accessor: "finish", align: "left" },
  ];

  const [rows, setRows] = useState([]);

  let onUpdateEvent = () => {};
  let onFinishEvent = () => {};

  const getData = async () => {
    try {
      const response = await getAllEvents();

      setEvents(response.data);
      const currentRows = [];
      response.data.forEach((event) => {
        currentRows.push({
          name: <h2>{event.name}</h2>,
          invoiceDesc: <p>{event.invoiceDescription}</p>,
          invoiceService: <p>{event.invoiceIsService ? 'Servicio' : 'Bien'}</p>,
          finish:
            event.active === true ? (
              <Tooltip title="Finalizar Evento">
                <IconButton
                  onClick={() => {
                    setCurrentEvent(event);
                    handleOpenFinishModal(event.id);
                  }}
                >
                  <CheckCircle color="success" />
                </IconButton>
              </Tooltip>
            ) : null,
          active:
            event.active === true ? (
              <MDTypography variant="p" style={{ fontWeight: "bold" }} color="success">
                Activo
              </MDTypography>
            ) : (
              <MDTypography variant="p" style={{ fontWeight: "bold" }} color="error">
                Inactivo
              </MDTypography>
            ),
          creator: <p>{event.company.name}</p>,
          options: (
            <MDBox>
              {event.active === true ? null : (
                <Tooltip title="Activar">
                  <IconButton
                    onClick={() => {
                      setCurrentEvent(event);
                      onUpdateEvent(event.id);
                    }}
                  >
                    <Public color="error" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Eliminar">
                <IconButton
                  onClick={() => {
                    setCurrentEvent(event);
                    handleOpen();
                  }}
                >
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
            </MDBox>
          ),
        });
      });
      setRows(currentRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  onUpdateEvent = async (id) => {
    try {
      await setActiveEvent(id);

      getData();
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  onFinishEvent = async () => {
    try {
      await editEvent(
        currentEvent.id,
        JSON.stringify({
          active: false,
        })
      );

      getData();
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Evento creado"
      content="Se ha creado un nuevo evento"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error al realizar la recarga"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: 24,
    p: 4,
  };

  const onDeleteEvent = async () => {
    try {
      const response = await deleteEvent(currentEvent.id);

      getData();
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const createNewEvent = async () => {
    if (name !== "") {
      handleCloseCreateNew();
      try {
        const response = await createAustriacoEvent(name, companyId, invoiceDesc, InvoiceService);
        setName("");
        openSuccessSB();
        getData();
      } catch (e) {
        if (e.message !== null) {
          setMessage(e.message);
        } else {
          setMessage("Error en el servidor");
        }
        openErrorSB();
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} display="flex" alignItems="center">
            <MDTypography variant="h3">Eventos</MDTypography>
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
            <Button
              style={{ height: 20 }}
              variant="contained"
              color="info"
              onClick={() => handleOpenCreateNew()}
            >
              Crear nuevo
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={6} mt={7}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Eliminar Evento
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Se eliminará toda la información del evento incluyendo sus tiendas, colaboradores y
            ventas
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              color="error"
              onClick={() => {
                onDeleteEvent();
                handleClose();
              }}
            >
              Eliminar
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
      <Modal
        open={openFinishModal}
        onClose={handleCloseFinishModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Finalizar Evento
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Se inactivará el evento y se bloquearan las compras.
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button onClick={handleCloseFinishModal}>Cancelar</Button>
            <Button
              color="error"
              onClick={() => {
                onFinishEvent();
                handleCloseFinishModal();
              }}
            >
              Finalizar
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
      <Modal
        open={openCreateNew}
        onClose={handleCloseCreateNew}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Crear Tienda
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormControl
              fullWidth
              style={{ margin: "8px 0 8px 0" }}
              onChange={(e) => setName(e.target.value)}
            >
              <TextField id="outlined-basic" label="Nombre" variant="outlined" />
              
              
            </FormControl>
            <FormControl
              fullWidth
              style={{ margin: "8px 0 8px 0" }}
              onChange={(e) => setInvoiceDesc(e.target.value)}
            >
              <TextField id="outlined-basic" label="Descripcion de factura" variant="outlined" />
            </FormControl>
            <FormControl
              fullWidth
              style={{ margin: "8px 0 8px 0" }}
            >
              <p style={{ 'fontSize': '13px' }}>Tipo de Factura</p>
              <TextField
              
                id="service"
                select
                defaultValue="event-"
                onChange={(e) => setInvoiceService(e.target.value)}
                SelectProps={{
                  native: true,
                }}
                helperText="Bien o Servicio"
              >
                <option key="event-" value="">
                  Selecciona una opcion
                </option>
                <option key='invoice-1' value='false'>
                  Bien
                </option>
                <option key='invoice-2' value='true'>
                  Servicio
                </option>
              </TextField>
            </FormControl>
            <FormControl fullWidth style={{ margin: "8px 0 8px 0" }}>
              <TextField
                id="events"
                select
                defaultValue="event-"
                onChange={(e) => setCompanyId(e.target.value)}
                SelectProps={{
                  native: true,
                }}
                helperText="Creador del evento"
              >
                <option key="event-" value="">
                  Selecciona una opción
                </option>
                <option value={1}>Asociación de Amigos Guatemaltecos y Austriacos</option>
                <option value={2}>El Tortugario</option>
              </TextField>
              </FormControl>
          </MDTypography>
          <MDBox xs={12} sx={{ paddingTop: "32px" }} display="flex" justifyContent="flex-end">
            <Button onClick={handleCloseCreateNew}>Cancelar</Button>
            <Button color="success" onClick={() => createNewEvent()}>
              Crear
            </Button>
          </MDBox>
        </MDBox>
      </Modal>
      {renderErrorSB}
      {renderSuccessSB}
    </DashboardLayout>
  );
}

export default Events;
